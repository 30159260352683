import type {GetStaticPaths, GetStaticProps, NextPage} from 'next';
import {useEffect} from 'react';
import {useRouter} from 'next/router';

import HtmlHead from '~components/organisms/Head';
import Homepage, {screens} from '~components/organisms/Homepage';
import MobileHomepage from '~components/organisms/Homepage/index.mobile';
import {useScopeTranslation} from '~hooks/useScopeTranslation';
import serverTranslations from '~utils/serverTranslations';
import Layout from '~components/organisms/Layout';
import ScrollNavigationProvider from '~components/providers/ScrollNavigation';
import RWDHelper from '~components/atoms/RWDHelper';
import {GlobalStyle} from '~components/organisms/Homepage/styles';

const Index: NextPage = () => {
    const {t} = useScopeTranslation('homepage');

    useEffect(() => {
        document.body.classList.add('loaded');
    }, []);

    const router = useRouter();

    return (
        <Layout>
            <GlobalStyle bgColor={router.query.screen ? 'white' : 'black'} />
            <ScrollNavigationProvider screens={screens} blockFirstScreen={true}>
                <HtmlHead title={t('pageTitle')} />
                <RWDHelper
                    desktop={<Homepage />}
                    mobile={<MobileHomepage />}
                    ssr={true}
                />
            </ScrollNavigationProvider>
        </Layout>
    );
};

export const getStaticProps: GetStaticProps = async ({locale}) => {
    return {
        props: {
            ...(await serverTranslations(['homepage'], locale)),
        },
    };
};

export default Index;

export const getStaticPaths: GetStaticPaths = () => {
    return {
        paths: [
            {params: {screen: undefined}},
            ...screens.slice(1).map((screen) => ({
                params: {screen: [screen]},
            })),
        ],
        fallback: false,
    };
};
