import {memo} from 'react';

import AboutUs from '~components/organisms/Homepage/sections/AboutUs';
import Preloader from '~components/organisms/Homepage/sections/Preloader';
import {useScrollNavigation} from '~components/providers/ScrollNavigation';
import GridBackground from '~components/atoms/GridBackground';

import Footer from '../Footer';
import HamburgerMenu from '../HambugerMenu';

import OurMission from './sections/OurMission';
import Character from './sections/Character';
import CreationProcess from './sections/CreationProcess';
import Stats from './sections/Stats';
import Gallery from './sections/Gallery';
import Superpowers from './sections/Superpowers';
// import MetaverseSlideshow from './sections/MetaverseSlideshow';
import Team from './sections/Team';
import Launch from './sections/Launch';
import {mobileMenuScreens} from '.';
import SpecialElements from './sections/SpecialElements';

type HomepageProps = {};

const MobileHomepage = ({}: HomepageProps) => {
    const {screen} = useScrollNavigation();

    return (
        <>
            <GridBackground />

            <Preloader />
            <AboutUs />
            <OurMission />
            <Character />
            <CreationProcess />
            <Stats />
            <Gallery />
            <Superpowers />
            <SpecialElements />
            {/*<MetaverseSlideshow />*/}
            <Team />
            <Launch />
            <Footer />

            <HamburgerMenu
                screensToShow={mobileMenuScreens}
                hideOnDesktop={screen === 'preloader'}
                color={'preloader' === screen ? 'white' : 'black'}
                background={'preloader' === screen ? 'transparent' : 'white'}
            />
        </>
    );
};

export default memo(MobileHomepage);
